import React, { Fragment } from 'react';
import {
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformExceptionList,
  Section,
  SectionSubhead,
} from '../../../components';
import pageHeroData from '../../../data/pages/patterns.yml';
import tabsPreviewData from '../../../data/previews/tabs.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      positionSelect: 'horizontal',
      styleSelect: 'contentselection',
    };
  }

  handlePositionChange = () => {
    this.setState({
      positionSelect:
        this.state.positionSelect === 'horizontal' ? 'vertical' : 'horizontal',
    });
  };

  handleStyleChange = () => {
    this.setState({
      styleSelect:
        this.state.styleSelect === 'contentselection'
          ? 'inheritenvironment'
          : 'contentselection',
    });
  };

  render() {
    return (
      <PageWithSubNav pageType="design" title="Tabs" subnav="patterns">
        <PageHero heroData={pageHeroData} tierTwo="Tabs" hideHeader />
        <PageNavigation links={['Position', 'Style', 'Mobile', 'Usage']} />

        <Section title="Position">
          <Paragraph>
            Tabs exist both horizontally and vertically. Sometimes, if the
            interface requires, use them in combination.
          </Paragraph>
          <ComponentPreview
            name="PatternsContainer"
            layout="fullWidth"
            hideEnvironmentToggle
            previewData={tabsPreviewData.position}
            onChange={this.handlePositionChange}>
            {this.state.positionSelect === 'horizontal' && (
              <img
                src={require('../../../imgs/previews/preview-tabs-position-horizontal.png')}
              />
            )}
            {this.state.positionSelect === 'vertical' && (
              <img
                src={require('../../../imgs/previews/preview-tabs-position-vertical.png')}
              />
            )}
          </ComponentPreview>
        </Section>
        <Section title="Style">
          <Paragraph>
            Depending on the content they’re showing, tabs may need to call more
            attention to themselves. In some cases, like the video player, tabs
            should take a backseat to the primary focus of the page.
          </Paragraph>
          <ComponentPreview
            name="PatternsContainer"
            layout="fullWidth"
            hideEnvironmentToggle
            previewData={tabsPreviewData.style}
            onChange={this.handleStyleChange}>
            {this.state.styleSelect === 'contentselection' && (
              <img
                src={require('../../../imgs/previews/preview-tabs-style-selection.png')}
              />
            )}
            {this.state.styleSelect === 'inheritenvironment' && (
              <img
                src={require('../../../imgs/previews/preview-tabs-style-environment.png')}
              />
            )}
          </ComponentPreview>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Tabs come in many different forms in mobile interface design.
            Between segmented controls, tab bars and switches, there are a lot
            of ways to divide content in a way that makes sense. But don’t
            worry, we’ve sorted when to use what below.
          </Paragraph>
          <List>
            <li>
              <strong>Segmented Control:</strong> If filtering between{' '}
              <em>only two</em> similar types of content, use a segmented
              control. This makes it simple to switch back and forth without
              things getting too complicated.
            </li>
            <li>
              <strong>Switch:</strong> Switches shouldn’t be confused with or
              used in place of tabs. Reserve these for enabling (or disabling)
              features and user preferences, like{' '}
              <Link href="/words/writing-guidelines/mobile-notifications">
                notifications
              </Link>
              .
            </li>
            <li>
              <strong>Tab Bar:</strong> Use tab bars when sectioning between
              areas of the product, like navigation. These are always fixed to
              the bottom of an app screen and shouldn’t be used in place of
              tabs.
            </li>
          </List>
          <br />
          <PlatformExceptionList
            platforms={{
              apple: (
                <Fragment>
                  Use Apple's{' '}
                  <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/segmented-controls/">
                    segmented control
                  </Link>
                  ,{' '}
                  <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/">
                    switch
                  </Link>
                  , and{' '}
                  <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/bars/tab-bars/">
                    tab bar
                  </Link>{' '}
                  components.
                </Fragment>
              ),
              android: (
                <Fragment>
                  Use Material's{' '}
                  <Link href="https://material.io/design/components/selection-controls.html#switches">
                    switch
                  </Link>{' '}
                  component.
                </Fragment>
              ),
            }}
          />
        </Section>
        <Section title="Usage">
          <SectionSubhead>Number of Tabs</SectionSubhead>
          <Paragraph>
            Too many tabs can make separated content difficult to find and
            having too few can hide content from view for no reason. With
            vertical space in abundance on both web and mobile, use as many tabs
            as makes sense. But space is at a premium horizontally, so limit the
            number of tabs to five.
          </Paragraph>
          <DontDo
            dontText="break up content into more tabs than necessary."
            doText="group similar content into a few tabs that make sense."
            imgFilename="tabs-number"
          />
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            Labels should describe the content within the tab it’s describing.
            We recommend labels be no more than two words. Internationalization
            may expand the label beyond that, but we’d rather be limited than
            unreadable.
          </Paragraph>
          <DontDo
            dontText="write sentences to describe the content within the tab."
            doText="keep it clear and easy to understand."
            imgFilename="tabs-label-length"
          />
          <Paragraph>
            In some cases, icons add an affordance to breaking up content. When
            using them in tabs, it’s all or nothing–either use them or don’t,
            don’t pick and choose. If you decide to use them, keep them to the
            left.
          </Paragraph>
          <SectionSubhead>Style</SectionSubhead>
          <Paragraph>
            When using tabs that follow our content selection, use the edge in
            the direction that flows into the content.
          </Paragraph>
          <DontDo
            dontText="add visual flourish to an active tab in a way that is confusing."
            doText="use selection to guide the eye to the enabled content."
            imgFilename="tabs-style"
          />
          <SectionSubhead>Content</SectionSubhead>
          <Paragraph>
            Only one tab should ever be active so the focus can be on the
            content shown within.
          </Paragraph>
          <DontDo
            dontText="make a user switch between tabs to complete a task."
            doText="separate content into smaller, relatable sections."
            imgFilename="tabs-switch"
          />
          <Paragraph>
            Only use tabs to navigate between similar types of content, not
            another area of the app or to complete a workflow.
          </Paragraph>
          <DontDo
            dontText="use tabs in place of buttons or links."
            doText="use them within the context of the tabbed content."
            imgFilename="tabs-buttons"
          />
          <PlatformExceptionList
            platforms={{
              apple:
                "When navigating to other portions of the app on mobile, use the tab bar component over Uniform's tab pattern.",
            }}
          />
        </Section>
      </PageWithSubNav>
    );
  }
}
